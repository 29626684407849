import { gql } from '@apollo/client';

const GuardianConfirmChangeOfPhoneNumber = gql`
  mutation GuardianConfirmChangeOfPhoneNumber($guardianId: UUID!, $confirmationCode: String!) {
    guardianConfirmChangeOfPhoneNumber(
      guardianId: $guardianId
      confirmationCode: $confirmationCode
    ) {
      guardian {
        id
        status
        phoneNumber
      }
    }
  }
`;

export default GuardianConfirmChangeOfPhoneNumber;
