import { Stack, Textarea, useMantineTheme } from '@mantine/core';
import { CustomQuestionItem } from 'interfaces';
import { CustomQuestionTypeEnum } from 'enums';
import YesNoButtons from '../YesNoButtons/YesNoButtons';
import YesNoInput from '../YesNoInput/YesNoInput';
import { PebbleSelect } from 'components/ui';
import classNames from 'classnames';
import classes from '../../FurtherInformationForm.module.scss';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { useState } from 'react';
import { showAllChoices } from 'components/FurtherInformationForm/utils';

interface CustomQuestionCardBookerAnswersProps {
  customQuestion: CustomQuestionItem;
  handleUpdateBookerAnswer: (questionId: string, value: string | null) => void;
  showInput: Record<string, boolean>;
  setShowInput: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  clearBookerError: () => void;
}

const CustomQuestionCardBookerAnswers: React.FC<CustomQuestionCardBookerAnswersProps> = ({
  customQuestion,
  handleUpdateBookerAnswer,
  showInput,
  setShowInput,
  clearBookerError,
}) => {
  const theme = useMantineTheme();
  const { question } = customQuestion;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const getYesNoInputValue = () => {
    if (customQuestion.bookerAnswer === 'YES') {
      return true;
    }
    if (customQuestion.bookerAnswer === 'NO') {
      return false;
    }
    return customQuestion.bookerAnswer ? true : null;
  };

  const showYesNoInput = () => {
    return (
      (customQuestion.bookerAnswer &&
        customQuestion.bookerAnswer !== 'YES' &&
        customQuestion.bookerAnswer !== 'NO') ||
      showInput[customQuestion.id]
    );
  };

  return (
    <>
      <Stack>
        {question.type === CustomQuestionTypeEnum.YES_NO && (
          <YesNoButtons
            value={
              customQuestion.bookerAnswer === 'YES'
                ? true
                : customQuestion.bookerAnswer === 'NO'
                ? false
                : null
            }
            onYes={() => handleUpdateBookerAnswer(customQuestion.id, 'YES')}
            onNo={() => handleUpdateBookerAnswer(customQuestion.id, 'NO')}
            questionShortTitle={customQuestion.question.shortTitle}
          />
        )}

        {question.type === CustomQuestionTypeEnum.YES_NO_INPUT && (
          <YesNoInput
            value={getYesNoInputValue()}
            onYes={() => {
              setShowInput((prevState) => ({
                ...prevState,
                [customQuestion.id]: true,
              }));
              handleUpdateBookerAnswer(customQuestion.id, '');
            }}
            onNo={() => {
              setShowInput((prevState) => ({
                ...prevState,
                [customQuestion.id]: false,
              }));

              handleUpdateBookerAnswer(customQuestion.id, 'NO');
            }}
            inputValue={customQuestion.bookerAnswer}
            onInputChange={(value) => handleUpdateBookerAnswer(customQuestion.id, value)}
            showInput={showYesNoInput()}
            questionShortTitle={customQuestion.question.shortTitle}
          />
        )}

        {question.type === CustomQuestionTypeEnum.TEXT_INPUT && (
          <Textarea
            autosize
            minLength={1}
            value={customQuestion.bookerAnswer || ''}
            onChange={(event) => handleUpdateBookerAnswer(customQuestion.id, event.target.value)}
            placeholder="Add information"
            data-testid={`${customQuestion.question.shortTitle} answer input`}
          />
        )}

        {question.type === CustomQuestionTypeEnum.SINGLE_SELECT && (
          <PebbleSelect
            size="md"
            data={showAllChoices(question.choices, customQuestion.bookerAnswer)}
            label=""
            onChange={(value) => {
              clearBookerError();
              handleUpdateBookerAnswer(customQuestion.id, value);
            }}
            placeholder="Choose"
            onDropdownOpen={() => {
              setDropdownOpen(true);
            }}
            value={
              customQuestion.bookerAnswer &&
              question.choices &&
              question.choices.length > 0 &&
              Object.values(question.choices).includes(customQuestion.bookerAnswer)
                ? customQuestion.bookerAnswer
                : undefined
            }
            onDropdownClose={() => setDropdownOpen(false)}
            rightSection={
              !dropdownOpen ? (
                <CaretDown
                  color={theme.colors.blue[8]}
                  size={14}
                  weight="bold"
                  className={classNames({
                    [classes.iconOpen]: dropdownOpen,
                    [classes.iconClose]: !dropdownOpen,
                  })}
                />
              ) : (
                <CaretUp
                  color={theme.colors.blue[8]}
                  size={14}
                  weight="bold"
                  className={classNames({
                    [classes.iconOpen]: dropdownOpen,
                    [classes.iconClose]: !dropdownOpen,
                  })}
                />
              )
            }
            classNames={{
              section: classes.rightSection,
              dropdown: classes.dropdown,
              option: classes.option,
              input: classes.input,
            }}
          />
        )}
      </Stack>
    </>
  );
};

export default CustomQuestionCardBookerAnswers;
