import { useMantineTheme, Badge, Text, Flex } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import React from 'react';
import { DESKTOP_FIC_FORM_WIDTH } from '../FurtherInformationForm';
import classes from './ProgressBar.module.scss';
import { FurtherInfoCaptureActivity } from 'interfaces';
import dayjs from 'dayjs';

interface ProgressBarProps {
  totalQuestions: number;
  answeredQuestions: number;
  precheckout?: boolean;
  furtherInfoActivity?: FurtherInfoCaptureActivity;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  totalQuestions,
  answeredQuestions,
  precheckout,
  furtherInfoActivity,
}) => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery('(max-width: 768px)', true);

  const startingProgress = 4;

  const progress = Math.max((answeredQuestions / totalQuestions) * 100, startingProgress);

  const getBarPosition = () => {
    if (precheckout) {
      return 'sticky';
    } else {
      return 'fixed';
    }
  };

  const noPreviousSubmissionBadge = () => {
    if (precheckout) {
      return (
        <span>
          {answeredQuestions} / {totalQuestions} Questions Completed
        </span>
      );
    }

    if (
      furtherInfoActivity?.additionalInformationCaptured &&
      furtherInfoActivity?.additionalInformationCapturedLastAt
    ) {
      return (
        <Text
          ml={isMobile ? '2px' : 'sm'}
          mt={isMobile ? 0 : '2px'}
          fw={700}
          size="18px"
          lh={'24px'}
          lts={'0.18px'}
        >
          {answeredQuestions} / {totalQuestions} Questions Completed{' '}
          <Text component="span" size="18px" lh={'24px'} lts={'0.18px'} fw={700} fs="italic">
            (Last submitted at:{' '}
            {dayjs(furtherInfoActivity.additionalInformationCapturedLastAt).format(
              'HH:mm, DD / MM / YYYY',
            )}
            )
          </Text>
        </Text>
      );
    }

    return (
      <Flex direction={isMobile ? 'column' : 'row'}>
        {answeredQuestions} / {totalQuestions} Questions Completed
        <Badge className={classes.badge} ml={isMobile ? '0' : 'xl'} mt={isMobile ? 0 : '6px'}>
          <Text c={theme.colors.red[8]} size="12px" fw={700} lh={'16px'} lts={'0.12px'}>
            Not yet submitted
          </Text>
        </Badge>
      </Flex>
    );
  };

  return (
    <div
      style={{
        position: getBarPosition(),
        top: isMobile ? 75 : 97,
        zIndex: precheckout ? 10 : 100,
        padding: '16px',
        borderRadius: theme.radius.sm,
        color: '#fff',
        backgroundColor: theme.colors.blue[8],
        opacity: 0.85,
        fontWeight: 700,
        fontSize: '18px',
        overflow: 'hidden',
        width: precheckout
          ? '100%'
          : isMobile
          ? `calc(100% - ${theme.spacing.lg} - ${theme.spacing.lg})`
          : DESKTOP_FIC_FORM_WIDTH,
        margin: 'auto',
      }}
    >
      {noPreviousSubmissionBadge()}
      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          height: '12px',
          width: `${progress}%`,
          backgroundColor: theme.colors.lime[8],
          transition: 'width 0.3s ease-in-out',
          borderRadius: theme.radius.sm,
        }}
      />
    </div>
  );
};

export default ProgressBar;
