import { gql } from '@apollo/client';

const GuardianChangePhoneNumber = gql`
  mutation GuardianChangePhoneNumber(
    $guardianId: UUID!
    $newPhoneNumber: String!
    $newPassword: String!
  ) {
    guardianChangePhoneNumber(
      guardianId: $guardianId
      newPhoneNumber: $newPhoneNumber
      newPassword: $newPassword
    ) {
      guardian {
        id
        status
        phoneNumber
      }
    }
  }
`;

export default GuardianChangePhoneNumber;
