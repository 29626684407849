import { gql } from '@apollo/client';

const UpdateMarketingOptIn = gql`
  mutation UpdateMarketingOptIn($guardianId: UUID!, $marketingOptIn: Boolean) {
    guardianUpdate(guardianId: $guardianId, marketingOptIn: $marketingOptIn) {
      guardian {
        id
        marketingOptIn
      }
      success
      errors {
        message
        code
      }
    }
  }
`;

export default UpdateMarketingOptIn;
