import { CustomQuestionItem } from 'interfaces';
import { CustomQuestionTypeEnum } from 'enums';

export const showAllChoices = (choices: string[] | null, answer: string | null) => {
  if (answer && choices && choices.length > 0 && !Object.values(choices).includes(answer)) {
    return [
      choices.map((choice: string) => ({ value: choice, label: choice, disabled: false })),
      { value: answer, label: answer, disabled: true },
    ]
      .flat()
      .sort((a, b) => a.label.localeCompare(b.label));
  } else {
    return choices?.map((choice) => ({ value: choice, label: choice, disabled: false }));
  }
};

export const hasUnavailableAnswer = (question: CustomQuestionItem) => {
  if (question.question.type === CustomQuestionTypeEnum.SINGLE_SELECT) {
    if (question.bookerAnswer && !question.question.choices?.includes(question.bookerAnswer)) {
      return true;
    }

    if (question.attendeeAnswers && question.attendeeAnswers.length > 0) {
      return question.attendeeAnswers.some(
        (attendeeAnswer) =>
          attendeeAnswer.answer && !question.question.choices?.includes(attendeeAnswer.answer),
      );
    }
  }
  return false;
};
