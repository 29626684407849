import { gql } from '@apollo/client';

const UpdateGuardianSupplierMarketingOptIns = gql`
  mutation updateGuardianSupplierMarketingOptIns($input: SupplierMarketingOptInsUpdateInputType!) {
    updateGuardianSupplierMarketingOptIns(input: $input) {
      guardianId
      supplierOptIns {
        supplierId
        supplierName
        optedIn
      }
    }
  }
`;

export default UpdateGuardianSupplierMarketingOptIns;
