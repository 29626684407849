import { Stack, Text, useMantineTheme, Textarea } from '@mantine/core';
import { CustomQuestionItem } from 'interfaces';
import { CustomQuestionTypeEnum } from 'enums';
import YesNoButtons from '../YesNoButtons/YesNoButtons';
import YesNoInput from '../YesNoInput/YesNoInput';
import { Actions, trackAction } from 'utils/amplitude';
import { PebbleSelect } from 'components/ui';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { Fragment, useState } from 'react';
import classes from '../../FurtherInformationForm.module.scss';
import classNames from 'classnames';
import { showAllChoices } from 'components/FurtherInformationForm/utils';

interface CustomQuestionCardAttendeeAnswersProps {
  customQuestion: CustomQuestionItem;
  handleUpdateAttendeeAnswer: (
    questionId: string,
    attendeeId: string,
    value: string | null,
  ) => void;
  showInput: Record<string, boolean>;
  setShowInput: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  clearAttendeeError: () => void;
}

const CustomQuestionCardAttendeeAnswers: React.FC<CustomQuestionCardAttendeeAnswersProps> = ({
  customQuestion,
  handleUpdateAttendeeAnswer,
  showInput,
  setShowInput,
  clearAttendeeError,
}) => {
  const theme = useMantineTheme();
  const { question } = customQuestion;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  if (customQuestion.question.isPerAttendee === false) {
    return null;
  }

  return (
    <Stack>
      {customQuestion.attendeeAnswers?.map((attendeeAnswer, index) => (
        <Fragment key={attendeeAnswer.attendeeId}>
          {attendeeAnswer !== null && (
            <Stack key={`${attendeeAnswer.attendeeId}-${index}`} gap={0}>
              <Text mb={theme.spacing.sm}>{attendeeAnswer.fullName}</Text>

              {question.type === CustomQuestionTypeEnum.YES_NO && (
                <YesNoButtons
                  data-testid={`${customQuestion.question.shortTitle} ${attendeeAnswer.fullName} answer`}
                  value={
                    attendeeAnswer.answer === 'YES'
                      ? true
                      : attendeeAnswer.answer === 'NO'
                      ? false
                      : null
                  }
                  onYes={() =>
                    handleUpdateAttendeeAnswer(customQuestion.id, attendeeAnswer.attendeeId, 'YES')
                  }
                  onNo={() =>
                    handleUpdateAttendeeAnswer(customQuestion.id, attendeeAnswer.attendeeId, 'NO')
                  }
                  questionShortTitle={customQuestion.question.shortTitle}
                  attendeeFullName={attendeeAnswer.fullName}
                />
              )}

              {question.type === CustomQuestionTypeEnum.YES_NO_INPUT && (
                <YesNoInput
                  value={
                    attendeeAnswer.answer === 'YES'
                      ? true
                      : attendeeAnswer.answer === 'NO'
                      ? false
                      : null
                  }
                  onYes={() => {
                    setShowInput((prevState) => ({
                      ...prevState,
                      [attendeeAnswer.attendeeId]: true,
                    }));

                    // init input value to empty string
                    handleUpdateAttendeeAnswer(customQuestion.id, attendeeAnswer.attendeeId, '');
                  }}
                  onNo={() => {
                    setShowInput((prevState) => ({
                      ...prevState,
                      [attendeeAnswer.attendeeId]: false,
                    }));

                    handleUpdateAttendeeAnswer(customQuestion.id, attendeeAnswer.attendeeId, 'NO');
                  }}
                  inputValue={attendeeAnswer.answer}
                  onInputChange={(value) => {
                    handleUpdateAttendeeAnswer(customQuestion.id, attendeeAnswer.attendeeId, value);
                  }}
                  showInput={showInput[attendeeAnswer.attendeeId] || false}
                  questionShortTitle={customQuestion.question.shortTitle}
                  attendeeFullName={attendeeAnswer.fullName}
                />
              )}

              {question.type === CustomQuestionTypeEnum.TEXT_INPUT && (
                <Textarea
                  autosize
                  minRows={1}
                  data-testid={`${customQuestion.question.shortTitle} ${attendeeAnswer.fullName} answer input`}
                  value={attendeeAnswer.answer || undefined}
                  onChange={(event) =>
                    handleUpdateAttendeeAnswer(
                      customQuestion.id,
                      attendeeAnswer.attendeeId,
                      event.currentTarget.value,
                    )
                  }
                  onBlur={() => trackAction(Actions.INFO_CHILD_ADDED)}
                />
              )}

              {question.type === CustomQuestionTypeEnum.SINGLE_SELECT && question.choices && (
                <>
                  <PebbleSelect
                    size="md"
                    data={showAllChoices(question.choices, attendeeAnswer.answer)}
                    label=""
                    onChange={(value) => {
                      clearAttendeeError();
                      handleUpdateAttendeeAnswer(
                        customQuestion.id,
                        attendeeAnswer.attendeeId,
                        value,
                      );
                    }}
                    value={
                      attendeeAnswer.answer &&
                      question.choices &&
                      question.choices.length > 0 &&
                      Object.values(question.choices).includes(attendeeAnswer.answer)
                        ? attendeeAnswer.answer
                        : undefined
                    }
                    placeholder="Choose"
                    onDropdownOpen={() => {
                      setDropdownOpen(true);
                    }}
                    onDropdownClose={() => setDropdownOpen(false)}
                    rightSection={
                      !dropdownOpen ? (
                        <CaretDown
                          color={theme.colors.blue[8]}
                          size={14}
                          weight="bold"
                          className={classNames({
                            [classes.iconOpen]: dropdownOpen,
                            [classes.iconClose]: !dropdownOpen,
                          })}
                        />
                      ) : (
                        <CaretUp
                          color={theme.colors.blue[8]}
                          size={14}
                          weight="bold"
                          className={classNames({
                            [classes.iconOpen]: dropdownOpen,
                            [classes.iconClose]: !dropdownOpen,
                          })}
                        />
                      )
                    }
                    classNames={{
                      section: classes.rightSection,
                      dropdown: classes.dropdown,
                      option: classes.option,
                      input: classes.input,
                    }}
                  />
                </>
              )}
            </Stack>
          )}
        </Fragment>
      ))}
    </Stack>
  );
};

export default CustomQuestionCardAttendeeAnswers;
